<span *ngIf="!studios || studios.length == 0" class="text-label no-items">KEINE STUDIOS GEFUNDEN</span>

<div *ngIf="studios && studios.length > 0" class="studios list">
    <h2>Alle Studios:</h2>    
    <div class="list-header">
        <span class="item-label name">Studioname</span> 
        <span class="item-label street">Straße</span> 
        <span class="item-label zip-city">PLZ u. Ort</span> 
        <span class="item-label mail">E-Mail</span> 
        <span class="item-label phone">Telefon</span>
        <span class="item-label web">Web</span>   
    </div>
    <ul class="studio-list">
        <li *ngFor="let studio of studios" class="studio item" (click)="onStudioClick(studio.Studionummer)">           
            <span class="item-value name">{{studio.Studioname}}</span> 
            <span class="item-value street">{{studio.street}}</span> 
            <span class="item-value zip-city">{{studio.zip}} {{studio.city}}</span> 
            <span class="item-value mail">{{studio.mail}}</span> 
            <span class="item-value phone">{{studio.phone}}</span>
            <span class="item-value web">{{studio.url}}</span>     
        </li>
    </ul>
</div>
