import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { StudioDataService as StudioService} from '../services/studio-data.service';
import { Studio } from '../api-models/studio';
import { Router } from '@angular/router';
import { ApplicationService, Navitem } from '../services/application.service';

@Component({
  selector: 'app-studio-list',
  templateUrl: './studio-list.component.html',
  styleUrls: ['./studio-list.component.sass']
})
export class StudioListComponent implements OnInit {

  public studios: Studio[];

  constructor(private applicationService: ApplicationService, private studioService: StudioService, private router: Router ) {

  }

  ngOnInit() {
    
    setTimeout(() => {

      this.applicationService.setPagetitle('Studioauswahl');
      this.applicationService.setLogoUrl(null);
      this.applicationService.setSidenav('contract_add');
      this.applicationService.setFooterNavVisibility(false);
      this.applicationService.setFootnotes([]);
      this.applicationService.updateSidenavItems((navitems: Navitem[]) => {
        
        navitems[0].active = true;
      });
    });
    
    this.studioService.getStudioList().subscribe((data) => {

      this.studios = data;
    });
  }

  onStudioClick(studioID: number) {

    this.router.navigate(['studio', studioID]);
  }
}
