<div class="header" style="text-align:center">
  <div class="animation-fix"></div>
  <div class="title">
    <div class="sticky-logo" [class.sticky]="sticky" alt="Logo" [ngStyle]="{'background-image': 'url(' + logoUrl + ')'}"></div>
    <img class="sticky-logo favicon" [class.sticky]="sticky" alt="Favicon" src="{{ faviconUrl }}">
    <p><strong>web<span class="light-blue">2<br />.0</span></strong>vertrag</p>
  </div>
  <img class="logo" alt="Agilea Logo" src="{{ logoUrl }}">
</div>
<div class="subheader" #stickyMenu [class.sticky]="sticky">
  <h1>{{pagetitle}}</h1>
</div>
<div *ngIf="sidenav && sidenav.visible" [class]="'sidenav ' + sidenav.name">
  <nav *ngIf="sidenav.navitems.length > 0">
      <ng-container *ngFor="let item of sidenav.navitems" >
      <a *ngIf="item.show" [routerLink]="item.routerlink" 
        [class.disabled]="item.disabled" [class.active]="item.active">
        <p>
          <i *ngIf="item.icon" class="{{item.icon}}"></i><br />
          <span>{{item.name}}</span>
        </p>
      </a>
      </ng-container>
  </nav>
</div>
<div id="main">
    <router-outlet></router-outlet>
    <div (click)="scrollTop()" [class.sticky]="sticky" class="to-top"><i class="fas fa-caret-up"></i></div>
</div>
<div id="footer">
  <div class="footer-cont">
    <span id="changeCookieSetting" class="pull-right"><i class="fa fa-cog" aria-hidden="true"></i><em> meine Cookie-Einstellungen anpassen</em></span>
    <ul class="footer-nav" *ngIf="footerNav && footerNav.visible">
      <ng-container *ngFor="let item of footerNav.navitems; index as i">
      <li *ngIf="!item.disabled">
        <a (click)="setFooterNavItemVisible(i)">{{item.name}}</a>
      </li>
    </ng-container>
    </ul>
    <ul class="footnotes" *ngIf="footnotes && footnotes.length > 0">
      <span class="foot-header">FUßNOTEN</span>
      <li *ngFor="let footnote of footnotes">
        <span class="footnote"><sup>*<sup>{{ footnote.number }}</sup></sup></span> {{ footnote.text }}
      </li>
    </ul>
  </div>
</div>