<div *ngIf="success">

    <h2>Dein Vertrag wurde erfolgreich freigschaltet.</h2>
    <p class="big">Dein Vertrag wurde erfolgreich freigschaltet. Du kannst nun ab Vertragsbeginn in deinem Studio einchecken.</p>
    <!-- <button [routerLink]="['/login']">Zum LogIn</button> -->
</div>
<div *ngIf="err">

    <h2>Leider konnte dein Vertrag nicht aktiviert werden.</h2>
    <p class="big">Wurde der Vertrag eventuell bereits freigeschaltet? Wende dich andernfalls bitte an dein Studio.</p>
    
    <!-- <p>Bitten wende dich an den Support: <a href="mailto:???">???</a></p> -->
</div>
<app-footer-nav-overlay [overlayData]="legalInfo"></app-footer-nav-overlay>