import { Component, OnInit, Input, SecurityContext } from '@angular/core';

import { ContractDataService as ContractService} from '../services/contract-data.service';
import { ContractTemplate } from '../api-models/contract-template';
import { ActivatedRoute, Router } from '@angular/router';
import { Studio } from '../api-models/studio';
import { StudioDataService } from '../services/studio-data.service';
import { ApplicationService, Navitem, Footnote } from '../services/application.service';
import { OrderService } from '../services/order.service';
import { StudioExtraData } from '../api-models/studio-extra-data';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-studio-details',
  templateUrl: './studio-details.component.html',
  styleUrls: ['./studio-details.component.sass']
})
export class StudioDetailsComponent implements OnInit {

  @Input() studioID: number;
  public templates: ContractTemplate[];
  public studio: Studio;
  public initialized: boolean = false;
  public studioExtraData: StudioExtraData;
  public logoUrl: string;
  public highlightColor: string;
  public footnotes: Map<number, Footnote> = new Map();
  public legalInfo: string[] = [];

  constructor(private cookieService: CookieService, private sanitizer: DomSanitizer, private orderService: OrderService, private applicationService: ApplicationService, private contractService: ContractService, private studioService: StudioDataService, private route: ActivatedRoute, private router: Router ) {

  }

  ngOnInit() {
    
    this.cookieService.set('studioSeen', (new Date()).getTime().toString(), null, "/", null, null, 'Lax');

    this.applicationService.getLogoUrl().subscribe((url) => {

      this.logoUrl = url;
    });

    this.applicationService.getCusColor().subscribe((color) => {

      this.highlightColor = color;
    });
  
    if (!this.studioID) {

      this.studioID = Number.parseFloat(this.route.snapshot.paramMap.get('id'));
    }

    this.studioService.getStudioDataByID(this.studioID).subscribe((data) => {

      this.studioExtraData = data;

      if ( this.studioExtraData ) {
        
        if ( this.studioExtraData.logoUrl )
          setTimeout(() => {
        
            this.applicationService.setLogoUrl(this.studioExtraData.logoUrl);
          });

        if ( this.studioExtraData.highlightColor )
          setTimeout(() => {
        
            this.applicationService.setCusColor(this.studioExtraData.highlightColor);
          });

        this.legalInfo = [data.agbHtml,data.impressumHtml,data.withdrawalHtml,data.privacyHtml];
      }
    });
    
    setTimeout(() => {
      
      this.applicationService.setPagetitle('Vertragsauswahl');
      this.applicationService.setSidenav('contract_add');

      this.applicationService.updateSidenavItems((navitems: Navitem[]) => {
        
        let navitem: Navitem = navitems[1];
        navitem.active = true;
        navitem.disabled = false;
        navitem.routerlink = '/studio/' + this.studioID.toString();

        navitem = navitems[2];
        navitem.active = false;
        navitem.disabled = true;
        navitem.routerlink = null;
      });

      this.applicationService.setFooterNavVisibility(true);
    });

    this.studioService.getStudioData(this.studioID).subscribe((studio) => {
      
      this.studio = studio;
      this.initialized = true;
    });;

    this.contractService.getTemplatesForStudio(this.studioID).subscribe((data) => {

      this.templates = data;
      
      let i = 1;
      data.forEach( (template) => {

        if ( template.wooDescription ) {

          this.footnotes.set(template.id, { number: i++, text: template.wooDescription });
        }
      });
      setTimeout( () => {

        this.applicationService.setFootnotes(Array.from(this.footnotes.values()));  
      });
    });
  }

  getContractTerm(index: number): string {

    if ( this.templates[index].cLength && this.templates[index].cLengthUnit ) {

      return this.orderService.getContractTerm(this.templates[index].cLength, this.templates[index].cLengthUnit);
    
    } else {

      return '-';
    }
  }

  getContractCycle(index: number): string {

    if ( this.templates[index].cCycle && this.templates[index].cCycleUnit ) {

      if ( this.templates[index].cLength && this.templates[index].cLengthUnit ) {

        return this.orderService.getContractCycle(this.templates[index].cCycle, this.templates[index].cCycleUnit, this.templates[index].cLength, this.templates[index].cLengthUnit); 
      }

      return this.orderService.getContractCycle(this.templates[index].cCycle, this.templates[index].cCycleUnit);
    }

    return '-';
  }

  getContractImageUrl(filename: string): SafeStyle {

    const url: string = this.sanitizer.sanitize(SecurityContext.URL, this.contractService.getAgileaImageUrl(filename));

    return this.sanitizer.bypassSecurityTrustStyle('background-image: url(' + url + ')');
  }
}
