import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractDataService as ContractService} from '../services/contract-data.service';
import { ContractTemplate } from '../api-models/contract-template';
import { MemberWithContract } from '../models/member-with-contract';
import { HttpClient } from '@angular/common/http';
import { ApplicationService, Navitem } from '../services/application.service';
import { PostingTemplate } from '../api-models/posting-template';
import { NgbDateStruct, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from '../services/order.service';
import { StudioExtraData } from '../api-models/studio-extra-data';
import { StudioDataService } from '../services/studio-data.service';
import { Bankaccount } from '../api-models/lib';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from "ngx-spinner";
import { Config } from 'src/config';

@Component({
  selector: 'app-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.sass']
})
export class ContractDetailsComponent implements OnInit {

  public initialized: boolean = false;
  public templateID: number;
  public studioID: number;
  public template: ContractTemplate;
  public postings: PostingTemplate[];
  public newContract: MemberWithContract;
  public selectedPostingsIndizes: number[];  
  public birthday: NgbDateStruct;
  public contractbegin: NgbDateStruct;
  public accessbegin: NgbDateStruct;
  public today = this.calendar.getToday();
  public earliestBirthday = this.calendar.getToday();
  public payment: string = 'sepa';
  public iban: string = '';
  public bic: string = '';
  public studioExtraData: StudioExtraData;
  public hasAgb: boolean = false;
  public agbAccepted: boolean = false;
  public sepaAccepted: boolean = false;
  public totalPriceOnce: number = 0;
  public totalPriceMonthly: number = 0;
  public logoURL: string = '';
  public paymentError: boolean = false;
  public syncError: boolean = false;
  public legalInfo: string[] = [];
  public hasFootnote: boolean = false;
  public services: string[] = [];
  public submitted: boolean = false;
  public success: boolean = false;
  public result: any;
  public sepaLink: any;
  public docLink: any;
  public isTabletModeOn: boolean = false;
  public sigError: boolean = false;
  public showErrors: boolean = false;
  public downloadUrl: string = Config.apiBasePath + '/getDocsGuest/';

  disabledDates = (date: NgbDate, current: {month: number}) => {

    if (this.template.cPaymentDay == 1)
      return date.day !== 1;

    if (this.template.cPaymentDay == 2)
      return date.day !== 15;

    return false;
  }

  getEarliestAccess(): NgbDate {

    let access: NgbDate = null;

    if ( this.contractbegin ) {

      access = new NgbDate(this.contractbegin.year, this.contractbegin.month, 1);
    }
      
    return access;
  }

  constructor(private spinner: NgxSpinnerService, private cookieService: CookieService, private studioService: StudioDataService, private orderService: OrderService, private calendar: NgbCalendar, private applicationService: ApplicationService, private http: HttpClient, private contractService: ContractService, private route: ActivatedRoute, private router: Router ) {

    this.earliestBirthday.year = this.earliestBirthday.year - 100;
  }

  setShowErrors(visible: boolean) {

    this.showErrors = visible;
  }

  ngOnInit() {

    this.applicationService.isTabletModeOn().subscribe( res => {

      this.isTabletModeOn = res;
    });

    this.applicationService.getLogoUrl().subscribe((url) => {
      
      this.logoURL = url;
    });

    this.templateID = Number.parseInt(this.route.snapshot.paramMap.get('id'));
    this.studioID = Number.parseInt(this.route.snapshot.paramMap.get('studioId'));

    this.contractService.getPostingsForContract(this.templateID).subscribe((postings) => {

      this.postings = postings;

      this.selectedPostingsIndizes = [];

      this.postings.forEach((posting, index) => {

        if (!posting.canVary) 
          this.selectedPostingsIndizes[index] = 1;
      });
    });
    

    this.contractService.getTemplateById(this.templateID).subscribe((data) => {

      this.template = data;

      if ( this.template.cService ) {

        this.contractService.getContractServices().subscribe((contractServices) => {
          
          const tempServices: string[] = this.template.cService.split(',');

          contractServices.forEach(service => {

            if (tempServices.includes(service.id.toString()))
              this.services.push(service.name);
          });
        });
      }        

      
      if ( data.wooDescription ) {

        this.hasFootnote = true;
        this.applicationService.setFootnotes([{number: 1, text: data.wooDescription}]);

      } else this.applicationService.setFootnotes([]);


      this.studioService.getStudioDataByID(this.studioID).subscribe((data) => {

        this.studioExtraData = data;
  
        if ( this.studioExtraData ) {
          
          setTimeout(() => {
            
            if ( this.studioExtraData.logoUrl )
              this.applicationService.setLogoUrl(this.studioExtraData.logoUrl);

            if ( this.studioExtraData.agbHtml )
              this.hasAgb = true;

            if ( this.studioExtraData.highlightColor )
              this.applicationService.setCusColor(this.studioExtraData.highlightColor);
              
          });

          this.legalInfo = [data.agbHtml,data.impressumHtml,data.withdrawalHtml,data.privacyHtml,data.sepaHtml];
        }
      });  

      setTimeout(() => {

        this.applicationService.setPagetitle('Vertragsanlage');
        this.applicationService.setSidenav('contract_add');        
        this.applicationService.updateSidenavItems((navitems: Navitem[]) => {
          
          let navitem: Navitem = navitems[2];
          navitem.active = true;
          navitem.disabled = false;
          navitem.routerlink = '/add-contract/' + this.templateID.toString();
  
          navitem = navitems[1];
          navitem.disabled = false;
          navitem.routerlink = '/studio/' + this.studioID;
        });
        this.applicationService.setFooterNavVisibility(true);

        this.calcSums();
      });  

      this.newContract = new MemberWithContract();

      let now: Date = new Date();
      let nowString: string = this.formatISO8601(now.getFullYear(), now.getMonth() + 1, now.getDate());
      
      this.newContract.type = 2;
      this.newContract.studio_id = this.studioID;
      this.newContract.firstName = "";
      this.newContract.lastName = "";
      this.newContract.gender = "";
      this.newContract.street = "";
      this.newContract.city = "";
      this.newContract.zip = "";
      this.newContract.email= "";
      this.newContract.phone1 = "";
      this.newContract.birthday = "";
      this.newContract.signingDate = nowString;
      this.newContract.accessBeginDate = nowString;
      this.newContract.contractBegin = nowString;
      this.newContract.cPaymentDay = this.template.cPaymentDay;
      this.newContract.contractTemplateId = this.templateID;
      this.newContract.cPaymentTypes = 1; // toDO: add other payment types     
      
      this.initialized = true;
    });
  }

  getContractTerm(): string {

    if ( this.template.cLength ) {

      let length = this.template.cLength;
      let contractTermString: string = length.toString();

      if ( this.template.cLengthUnit ) {
        let temp: string = '';
        switch ( this.template.cLengthUnit ) {
          case 1:
            length == 1 ? temp = ' Tag'   : temp = ' Tage';
            contractTermString += temp;
            break;
          case 2:
            length == 1 ? temp = ' Monat' : temp = ' Monate';
            contractTermString += temp;
            break;
          case 3:
            length == 1 ? temp = ' Jahr'  : temp = ' Jahre';
            contractTermString += temp;
            break;
          case 4:
            length == 1 ? temp = ' Woche'  : temp = ' Wochen';
            contractTermString += temp;
            break;
          }

        return contractTermString;
      }
    }
    
    return '-';
  }

  getPostingCycle(index: number): string {
  
    let postingCycleString: string;

    if ( this.postings[index].p_interval ) {

      let length: number = this.postings[index].p_interval;
      length == 1 ? postingCycleString = ''  : postingCycleString = length.toString() + '-';

      if ( this.postings[index].unit ) {

        switch ( this.postings[index].unit ) {
          case 1:
            postingCycleString += 'tgl.'
            break;
          case 2:
            postingCycleString += 'mtl.';
            break;
          case 3:
            postingCycleString += 'jhl.';
            break;
          case 4:
            postingCycleString += 'wtl.';
            break;
        }

      } else postingCycleString = 'einm.';
      
    } else postingCycleString = 'einm.';
  
    return postingCycleString;
  }

  getContractCycle(): string {

    return this.orderService.getContractCycle(this.template.cCycle, this.template.cCycleUnit, this.template.cLength, this.template.cLengthUnit);
  }

  getMonthlyCosts(index: number = null): number {

    if ( index != null ) {
    
      if ( this.postings[index].p_interval && this.postings[index].unit ) {
        
        return this.orderService.getMonthlyCosts(this.postings[index].fee, this.postings[index].p_interval, this.postings[index].unit);

      } else {
        
        return null;
      }
    }

    return this.orderService.getMonthlyCosts(this.template.cFee, this.template.cCycle, this.template.cCycleUnit, this.template.cLength, this.template.cLengthUnit);
  }

  calcSums(): void {

    this.totalPriceMonthly = 0;
    this.totalPriceOnce = 0;

    const contractCosts: number = this.getMonthlyCosts();

    if ( contractCosts ) 

      this.totalPriceMonthly += contractCosts;

    else
      this.totalPriceOnce += this.template.cFee;
    

    this.postings.forEach( (posting, index) => {
      
      if ( this.selectedPostingsIndizes[index] || !posting.canVary ) {
        
        const postingCosts: number = this.getMonthlyCosts(index);

        if ( postingCosts ) 

          this.totalPriceMonthly += postingCosts;

        else
          this.totalPriceOnce += posting.fee;
      } 

    });
    
  }
      
  private buildSubscriptionsArray(): void {
    
    let subs: string[] = [];

    this.selectedPostingsIndizes.forEach((selection, index) => {

      if ( selection ) {

        subs.push(this.postings[index].postingTemplateId + '_§_' + this.postings[index].fee);
      }
    });

    this.newContract.subscriptionArray = subs.join(':-:');
  }

  private formatISO8601(year, month, day) {
    
    let dateString = year.toString() + '-';

    ( /^\d$/.test(month.toString()) ) ? dateString += '0' + month + '-' : dateString += month + '-';
    ( /^\d$/.test(day.toString()) )   ? dateString += '0' + day         : dateString += day;

    return dateString;
  }

  openAgb(event: Event): void {

    event.preventDefault();

    this.applicationService.updateFooterNavItems((items) => {

      items[0].show = true;
    });
  }

  openSepa(event: Event): void {

    event.preventDefault();

    this.applicationService.updateFooterNavItems((items) => {

      items[4].show = true;
    });
  }

  private formatBankaccount() {

    this.iban = this.iban.replace(/\s/g,'').toUpperCase();
    this.bic = this.bic.replace(/\s/g,'').toUpperCase();

    if (this.bic.length == 8) {

      this.bic += 'XXX';    
    }
  }

  async onSubmit() {

    if ( this.isTabletModeOn && !this.newContract.signature ) {

      this.sigError = true;
      return;
    }

    this.syncError = false;
    this.spinner.show();

    await this.orderService.getLastSyncTime().toPromise().then((res: any) => {
      
      if ( res && res.lastSync && res.lastSync > 0 ) {

        if ( this.cookieService.check('studioSeen') && (this.cookieService.get('studioSeen') < res.lastSync) ) {

          this.syncError = true;
        }
      }
    }, err => {

      console.log('ERROR cannot verify last sync time! Msg: ' + err);
    });
  
    if ( this.syncError ) {
     
      this.contractService.getPostingsForContract(this.templateID).subscribe((postings) => {

        this.postings = postings;
      });
      
      this.contractService.getTemplateById(this.templateID).subscribe((data) => {
  
        this.template = data;
      });

      return;
    }
    
    this.paymentError = false;

    if ( this.payment == 'sepa') {
      
      this.formatBankaccount();
      const bankaccount: Bankaccount = { iban: this.iban, bic: this.bic };

      await this.orderService.checkBankaccount(bankaccount).toPromise().then(res => {

        if ( !res.success )
          
          this.paymentError = true;

        else {

          this.newContract.iban = this.iban;
          this.newContract.bic = this.bic;
          this.newContract.owner = this.newContract.firstName + ' ' + this.newContract.lastName;
        }  

      }, err => {
        
        this.paymentError = true;
      });

      if ( this.paymentError ) {

        this.spinner.hide();
        return;
      }
    }

    this.buildSubscriptionsArray();
    
    this.newContract.birthday = this.formatISO8601(this.birthday.year, this.birthday.month, this.birthday.day);
    this.newContract.contractBegin = this.formatISO8601(this.contractbegin.year, this.contractbegin.month, this.contractbegin.day);
    
    if ( this.accessbegin ) 
      this.newContract.accessBeginDate = this.formatISO8601(this.accessbegin.year, this.accessbegin.month, this.accessbegin.day);
    else
      this.newContract.accessBeginDate = this.newContract.contractBegin;
      
    this.orderService.createWebcontract(this.newContract).subscribe((res) => {

      this.submitted = true;      

      if ( res.success ) {

        this.success = true;
        this.result = JSON.parse((res.data as any).initialPostings);

        this.docLink= (res.data as any).docLink;
        this.sepaLink= (res.data as any).sepaLink;
      
        this.result.forEach((initial) => {
          initial.expiryDate = initial.expiryDate.split(".")[0];
          initial.expiryDate = initial.expiryDate.replace(" ","T");
        });

      } else {

        this.success = false;
      }

      this.spinner.hide();
       
    }, (error) => {

      this.submitted = true;
      this.success = false;
      this.spinner.hide();
    });
  }

  discardSyncError() {

    this.syncError = false;
    this.cookieService.set('studioSeen', (new Date()).getTime().toString(), null, '/', null, null, 'Lax');
  }

  onSignatureChange(signaturePng) {

    this.newContract.signature = signaturePng;
    this.sigError = false;
  } 
}
