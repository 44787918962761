import { Injectable, Inject } from '@angular/core';
import { Bankaccount, ApiResponseWithData, BasicApiResponse } from '../api-models/lib';
import { HttpClient } from '@angular/common/http';
import { API_BASE_PATH } from './api-client-vars';
import { Observable } from 'rxjs';
import { MemberWithContract } from '../models/member-with-contract';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private API_BASE_PATH: string;

  constructor(@Inject(API_BASE_PATH) apiBasePath: string, private http: HttpClient) { 

    this.API_BASE_PATH = apiBasePath;
  }

  checkActivation(token: string): Observable<BasicApiResponse> {

    return this.http.get<BasicApiResponse>(this.API_BASE_PATH + '/user/activate/' + token);
  }

  getLastSyncTime() {

    return this.http.get(this.API_BASE_PATH + '/last-sync');
  }

  checkBankaccount(bankaccount: Bankaccount): Observable<ApiResponseWithData> {

    return this.http.post<ApiResponseWithData>(this.API_BASE_PATH + '/proxy/check-bankaccount', bankaccount);
  }

  createWebcontract(webcontractData: MemberWithContract): Observable<ApiResponseWithData> {

    if ( webcontractData.signature ) return this.http.post<ApiResponseWithData>(this.API_BASE_PATH + '/proxy/webcontract-tablet/', webcontractData);

    return this.http.post<ApiResponseWithData>(this.API_BASE_PATH + '/proxy/webcontract/', webcontractData);
  }

  getContractTerm(cLength: number, cLengthUnit: number): string {
    
    let contractTermString: string = cLength.toString() + " ";

    if (cLength == 1) {
      switch ( cLengthUnit ) {
        case 1:
          contractTermString += 'Tag';
          break;
        case 2:
          contractTermString += 'Monat';
          break;
        case 3:
          contractTermString += 'Jahr';
          break;
        case 4:
          contractTermString += 'Woche';
          break;
      }  
    } else {
      switch ( cLengthUnit ) {
        case 1:
          contractTermString += 'Tage';
          break;
        case 2:
          contractTermString += 'Monate';
          break;
        case 3:
          contractTermString += 'Jahre';
          break;
        case 4:
          contractTermString += 'Wochen';
          break;
      }  
    }
    
    return contractTermString;
  }

  getContractCycle(cCycle: number, cCycleUnit: number, cLength?: number, cLengthUnit?: number): string {
  
    let length: number = cCycle;
    let contractCycleString: string;
    length == 1 ? contractCycleString = ''  : contractCycleString = length.toString() + '-';

    switch ( cCycleUnit ) {
      case 1:
        contractCycleString += 'tgl.'
        break;
      case 2:
        contractCycleString += 'mtl.';
        break;
      case 3:
        contractCycleString += 'jhl.';
        break;
      case 4:
        contractCycleString += 'wtl.';
        break;
    }

    if (( cLength && cLength == cCycle ) && 
        ( cLengthUnit && cLengthUnit == cCycleUnit )) {
      
      contractCycleString = 'einm.';
    }
    
    return contractCycleString;
  }


  getMonthlyCosts(cFee: number, cCycle: number, cCycleUnit: number, cLength?: number, cLengthUnit?: number) {
    
    let costs: number = cFee / cCycle; 

    switch ( cCycleUnit ) {
      case 1:
        costs = costs * 7 * 4;
        break;
      case 2:
        costs = costs; // ;-)
        break;
      case 3:
        costs = costs / 12;
        break;
      case 4:
        costs = costs * 4;
        break;
    }

    if (( cLength && cLength == cCycle ) && 
       ( cLengthUnit && cLengthUnit == cCycleUnit )) {
  
      return null;
    }

    return costs;
  }

}
