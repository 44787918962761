export class MemberWithContract {
    
    type: number;
    studio_id: number;
    firstName: string;
    lastName: string;
    gender: string;
    street: string;
    zip: string;
    city: string;
    phone1: string;
    email: string;
    birthday: string;
    signingDate: string;
    contractBegin: string;
    accessBeginDate: string;
    cPaymentDay: number;
    contractTemplateId: number;
    cPaymentTypes: number;
    subscriptionArray: string;
    iban: string;
    bic: string;
    owner: string;
    signature?: string;
}
