import { Component, OnInit, HostListener, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.sass']
})
export class SignaturePadComponent implements OnInit {

  @ViewChild('sPad', { static: true }) signaturePadElement;
  @ViewChild('sCont', { static: true }) signaturePadContainer;
  @Output() signaturePng = new EventEmitter<string>();
  signaturePad: any;

  signaturePadOptions: Object = {};
  signature: string = null;

  constructor(private elementRef: ElementRef) { }

  /**
   * Sets the styles and initiates the canvas
   *
   * @memberof SignaturePage
   */
  ngOnInit(): void {

  }

  clear() {
    
    this.signaturePad.clear();
    this.signaturePng.emit(null);
    this.signature = null;
  }

  undo() {

    const data = this.signaturePad.toData();

    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }
  }

  // FORCE to recreate signature pad
  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.resize();
  }

  resize() {

    const container = this.signaturePadContainer.nativeElement;
    
    const borderWidth = 3;
    const pageWidth = container.offsetWidth - 2 * borderWidth;
    const pageHeight = container.offsetWidth / 2;

    const canvas: any = this.elementRef.nativeElement.querySelector('canvas');
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    // const canvas: any = this.signaturePad._canvas;
    canvas.width  =  pageWidth;
    canvas.height = pageHeight;
    
    if (this.signaturePad) {

      this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
      this.signaturePng.emit(null);
      this.signature = null;
    }
  }

  public ngAfterViewInit(): void {

    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);

    this.resize();
    this.signaturePad.clear();
    this.signaturePng.emit(null);
    this.signature = null;
  }

  /**
   * Closes the modal and passes the signature to the page where it is being requested
   *
   * @memberof SignaturePage
   */
  save(): void {

    this.signature = this.signaturePad.toDataURL('image/png');

    this.signaturePng.emit(this.signature);
  }

  /**
   * returns true there is no signature, false if there is a signature
   *
   * @returns
   * @memberof SignaturePage
   */
  isCanvasBlank(): boolean {
  
    if (this.signaturePad) {

      return this.signaturePad.isEmpty() ? true : false;
    }
  }

}

