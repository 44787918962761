import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Nav, ApplicationService } from '../services/application.service';

@Component({
  selector: 'app-footer-nav-overlay',
  templateUrl: './footer-nav-overlay.component.html',
  styleUrls: ['./footer-nav-overlay.component.sass']
})
export class FooterNavOverlayComponent implements OnInit {

  @Input('overlayData') 
  private overlayData: string[] = [];
  
  private footerNav: Nav;
  
  constructor(private applicationService: ApplicationService) {

  }

  ngOnInit(): void {

    this.applicationService.getFooterNav().subscribe((nav) => {

      this.footerNav = nav;
    });
  }
  
  getOverlayData(index: number): string {

    return this.overlayData[index] ? this.overlayData[index] : null;
  }

  showOverlay(): boolean {

    let res: boolean = false;

    if (this.footerNav && this.footerNav.navitems)
      
      this.footerNav.navitems.forEach((item) => {

        if ( item.show ) res = true;
      });

    return res;
  }

  closeOverlay(): void {

    if (this.footerNav)
      
      this.applicationService.updateFooterNavItems((items) => {
      
        items.forEach((item) => {

          item.show = false;
        });
      });
  }

  overlayFix(event: Event): void {

    event.stopPropagation();
  }

}
