import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractTemplate } from '../api-models/contract-template';
import { HttpClient } from '@angular/common/http';
import { PostingTemplate } from '../api-models/posting-template';
import { ContractPostings } from '../api-models/contract-postings';
import { API_BASE_PATH, IMAGE_BASE_URL } from './api-client-vars';
import { ContractService } from '../api-models/contract-service';

@Injectable({
  providedIn: 'root'
})
export class ContractDataService {

  private API_BASE_PATH: string;
  private IMAGE_BASE_URL: string;

  constructor(@Inject(API_BASE_PATH) apiBasePath: string, @Inject(IMAGE_BASE_URL) imageBaseUrl: string, private http: HttpClient) {

    this.API_BASE_PATH = apiBasePath;
    this.IMAGE_BASE_URL = imageBaseUrl;
  }

  getAgileaImageUrl(filename: string): string {

    return this.IMAGE_BASE_URL + filename;
  }

  getTemplates(): Observable<ContractTemplate[]> {

    return this.http.get<ContractTemplate[]>(this.API_BASE_PATH + '/contract-templates');
  }

  getTemplatesForStudio(studioID: number): Observable<ContractTemplate[]> {

    return this.http.get<ContractTemplate[]>(this.API_BASE_PATH.toString() + '/contract-templates?studioID=' + studioID);
  }

  getTemplateById(templateID: number): Observable<ContractTemplate> {

    return this.http.get<ContractTemplate>(this.API_BASE_PATH + '/contract-template/' + templateID);
  }

  getPostingsForContract(templateID: number): Observable<PostingTemplate[]> {

    return this.http.get<PostingTemplate[]>(this.API_BASE_PATH + '/contract-postings/' + templateID);
  }

  getContractPostings(): Observable<ContractPostings[]> {

    return this.http.get<ContractPostings[]>(this.API_BASE_PATH + '/contract-postings/');
  }

  getContractServices(): Observable<ContractService[]> {

    return this.http.get<ContractService[]>(this.API_BASE_PATH + '/contract-services/');
  }

  getPostings(): Observable<PostingTemplate[]> {

    return this.http.get<PostingTemplate[]>(this.API_BASE_PATH + '/posting-templates/');
  }

}
