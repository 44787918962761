import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
}) 
export class ApiSessionInterceptor implements HttpInterceptor {

    constructor(private cookieService: CookieService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        let token: string;
        
        if ( this.cookieService.check('wbctSession') )
            token = this.cookieService.get('wbctSession');

        if (token) {
            request = request.clone({ headers: request.headers.set('wbctSession', token) });
        }

        return next.handle(request);
    }    

}
