import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, InjectionToken } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { StudioListComponent } from './studio-list/studio-list.component';
import { StudioDetailsComponent } from './studio-details/studio-details.component';
import { ContractDetailsComponent } from './contract-details/contract-details.component';
import { FormsModule }   from '@angular/forms';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { NgbDatepickerModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerFormatterDE } from './utils/date-picker-formatter-de';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiSessionInterceptor} from './interceptors/api-session.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { Config } from 'src/config';
import { API_BASE_PATH, IMAGE_BASE_URL } from './services/api-client-vars';
import { FooterNavOverlayComponent } from './footer-nav-overlay/footer-nav-overlay.component';
import { ActivateUserComponent } from './activate-user/activate-user.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { SignaturePadComponent } from './signature-pad/signature-pad.component';

registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [
    AppComponent,
    StudioListComponent,
    StudioDetailsComponent,
    ContractDetailsComponent,
    FooterNavOverlayComponent,
    ActivateUserComponent,
    SignaturePadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbDatepickerModule,
    NgxSpinnerModule
  ],
  providers: [
    CookieService,
    { provide: LOCALE_ID, useValue: 'de' }, 
    { provide: NgbDateParserFormatter, useClass: DatePickerFormatterDE },
    { provide: HTTP_INTERCEPTORS, useClass: ApiSessionInterceptor, multi: true },
    { provide: API_BASE_PATH, useValue: Config.apiBasePath },
    { provide: IMAGE_BASE_URL, useValue: Config.imageBaseUrl },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
