import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../services/order.service';
import { ApplicationService } from '../services/application.service';
import { StudioDataService } from '../services/studio-data.service';

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.sass']
})
export class ActivateUserComponent implements OnInit {

  private token: string;
  private studio: number;
  public err: boolean = false;
  public success: boolean = false;
  public legalInfo: string[] = [];

  constructor(private route: ActivatedRoute, private orderService: OrderService, private applicationService: ApplicationService, private studioService: StudioDataService) { }

 
  ngOnInit(): void {
  
    setTimeout(() => {

      this.applicationService.setPagetitle('Vertrag Freischalten');
      
      this.applicationService.setSidenavVisibility(false);
      this.applicationService.setFooterNavVisibility(false);
    }); 

    if ( this.route.snapshot.queryParamMap.has('studio') ) {

      this.studio = Number.parseInt(this.route.snapshot.queryParamMap.get('studio'));

      this.studioService.getStudioDataByID(this.studio).subscribe((data) => {

        if ( data ) {
          
          setTimeout(() => {
            
            if ( data.logoUrl )
              this.applicationService.setLogoUrl(data.logoUrl);

            if ( data.highlightColor )
              this.applicationService.setCusColor(data.highlightColor);

            this.legalInfo = [data.agbHtml,data.impressumHtml,data.withdrawalHtml,data.privacyHtml,data.sepaHtml];
            this.applicationService.setFooterNavVisibility(true);  
          });
        }
      });
    }

    if ( this.route.snapshot.queryParamMap.has('token') ) {
      
      this.token = this.route.snapshot.queryParamMap.get('token');
      
      this.orderService.checkActivation(this.token).subscribe(success => {

        if ( success ) {

          this.success = true;

        } else {

          this.err = true;
        }

      }, (err) => {

        this.err = true; 
      });

    } else this.err = true;
  }

}
