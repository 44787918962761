import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StudioListComponent } from './studio-list/studio-list.component';
import { StudioDetailsComponent } from './studio-details/studio-details.component';
import { ContractDetailsComponent } from './contract-details/contract-details.component';
import { ActivateUserComponent } from './activate-user/activate-user.component';

const routes: Routes = [
  { path: '', redirectTo: '/studios', pathMatch: 'full' },
  { path: 'studios', component: StudioListComponent },
  { path: 'studio/:id', component: StudioDetailsComponent },
  { path: 'add-contract/:studioId/:id', component: ContractDetailsComponent },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'aktivierung', component: ActivateUserComponent },
  { path: '**', redirectTo: '/studios'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
