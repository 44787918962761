import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Studio } from '../api-models/studio';
import { StudioExtraData } from '../api-models/studio-extra-data';
import { BasicApiResponse, ApiResponseWithData } from '../api-models/lib';
import { API_BASE_PATH } from './api-client-vars';


@Injectable({
  providedIn: 'root'
})
export class StudioDataService {

  private API_BASE_PATH: string;

  constructor(@Inject(API_BASE_PATH) apiBasePath: string, private http: HttpClient) {

    this.API_BASE_PATH = apiBasePath;
  }

  getStudioList(): Observable<Studio[]> {

    return this.http.get<Studio[]>(this.API_BASE_PATH + '/studios');
  }

  getStudioData(studioID: number): Observable<Studio> {

    return this.http.get<Studio>(this.API_BASE_PATH + '/studio/' + studioID); 
  }

  getStudioExtraData(): Observable<StudioExtraData[]> {

    return this.http.get<StudioExtraData[]>(this.API_BASE_PATH + '/studio-data');
  }

  getStudioDataByID(id: number): Observable<StudioExtraData> {

    return this.http.get<StudioExtraData>(this.API_BASE_PATH + '/studio-data-id/' + id);
  }

  setStudioExtraData(studioData: StudioExtraData): Observable<BasicApiResponse> {

    return this.http.post<BasicApiResponse>(this.API_BASE_PATH + '/studio-data', studioData, {withCredentials: true});
  }

  writeDocs(studioID: number): Observable<ApiResponseWithData> {

    return this.http.get<ApiResponseWithData>(this.API_BASE_PATH + '/write-docs/' + studioID, {withCredentials: true});
  }

}
