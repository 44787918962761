<div class="signature-pad-container">
    
    <div class="signature-pad" #sCont>
      <canvas class="signature-pad-canvas" #sPad style="touch-action: none;"></canvas>
    </div>

    <div class="btn" *ngIf="!isCanvasBlank()" (click)="clear()">LÖSCHEN</div>
    <i *ngIf="signature" class="check-icon fas fa-check-circle"></i>
    <div class="btn" *ngIf="!isCanvasBlank()" (click)="save()">SPEICHERN</div>

</div>
  